import { defineRouting } from 'next-intl/routing';
import { createNavigation } from 'next-intl/navigation';

export const locales = ['en-RO', 'ro-RO', 'en-MD', 'ru-MD', 'ro-MD'];

export const routing = defineRouting({
  // A list of all locales that are supported
  locales,

  // Used when no locale matches
  defaultLocale: 'ro-MD',

  localeDetection: true,

  domains: [
    {
      defaultLocale: 'ro-MD',
      domain: 'www.courtica.md',
      locales: locales.filter((locale) => locale.includes('-MD')),
    },
    {
      defaultLocale: 'ro-RO',
      domain: 'www.courtica.ro',
      locales: locales.filter((locale) => locale.includes('-RO')),
    },
  ],
});

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const { Link, redirect, usePathname, useRouter, getPathname } = createNavigation(routing);
export type Locale = (typeof routing.locales)[number];
