import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getLangFromLocale(locale: string): 'en' | 'ro' | 'ru' {
  const [lang, country] = locale.split('-');

  return lang as 'en' | 'ro' | 'ru';
}
