'use client';

import { usePathname } from '@/i18n/routing';
import Logo from './LogoNew';
import { Link } from '@/i18n/routing';
import { Facebook, Instagram } from 'lucide-react';
import { useTranslations } from 'next-intl';
export default function Footer() {
  const pathname = usePathname();
  const t = useTranslations('footer');
  if (pathname.startsWith('/dash') || pathname.startsWith('/admin')) {
    return null;
  }
  return <footer className="bg-[#1C1C1C] text-white py-8" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <div className="container mx-auto px-4">
        <div className="flex flex-col space-y-8 md:space-y-4">
          <div className="flex items-center gap-2">
            <Logo mode="light" data-sentry-element="Logo" data-sentry-source-file="Footer.tsx" />
          </div>

          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
            <div className="space-y-4 mb-6 sm:mb-0">
              <div className="text-sm text-gray-300">
                <p>{t('copyright', {
                  year: new Date().getFullYear()
                })}</p>
                <div className="space-x-2 mt-2">
                  <Link href="/terms-and-conditions" className="hover:text-gray-100" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                    {t('terms')}
                  </Link>
                  <span>•</span>
                  <Link href="/blog" className="hover:text-gray-100" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                    {t('blog')}
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-left sm:text-right">
              <h2 className="text-sm font-semibold mb-2">{t('contact_us')}</h2>
              <div className="space-y-1 text-sm text-gray-300">
                <p>
                  {t('phone')}{' '}
                  <a href="tel:+37368891937" className="hover:text-gray-100">
                    +37368891937
                  </a>
                </p>
                <a href="mailto:support@courtica.md" className="block hover:text-gray-100">
                  support@courtica.md
                </a>
              </div>
            </div>
          </div>

          <div className="flex justify-center sm:justify-end gap-4 pt-4 border-t border-gray-700">
            <a href="#" className="text-gray-300 hover:text-white" aria-label="Facebook">
              <Facebook className="w-5 h-5" data-sentry-element="Facebook" data-sentry-source-file="Footer.tsx" />
            </a>
            <a href="#" className="text-gray-300 hover:text-white" aria-label="Instagram">
              <Instagram className="w-5 h-5" data-sentry-element="Instagram" data-sentry-source-file="Footer.tsx" />
            </a>
          </div>
        </div>
      </div>
    </footer>;
}