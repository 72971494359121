import { addMinutes, format, getDay, isBefore, isSameDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const daysOfTheWeek = ['1', '2', '3', '4', '5', '6', '7'];

export const marketingTimes = [
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

// this function returns the times that are available for booking depending on the current time of the user
export const getCurrentTimeFilteredTimes = (selectedDate: Date): string[] => {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();

  if (!isSameDay(selectedDate, now)) {
    return marketingTimes;
  }

  const roundedHour = currentMinute < 30 ? currentHour : currentHour + 1;
  const roundedMinute = currentMinute < 30 ? '30' : '00';

  const startIndex = marketingTimes.indexOf(`${roundedHour < 10 ? `0${roundedHour}` : roundedHour}:${roundedMinute}`);
  if (startIndex === -1) {
    return marketingTimes;
  }

  return marketingTimes.slice(startIndex);
};

export const getChisinauDate = (d: Date = new Date()): string => {
  // check if Intl is not available
  if (!Intl) {
    return format(d, 'yyyy-MM-dd HH:mm:ss');
  }

  return formatInTimeZone(d, 'Europe/Chisinau', 'yyyy-MM-dd HH:mm:ss');
};

export function getDayOfWeek(date: Date | string) {
  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const day = getDay(date);

  // Adjust the value so that Monday is 1 and Sunday is 7
  return day === 0 ? 7 : day;
}

export const buildEndTime = (date: string, startTime: string, duration: number): string => {
  return format(addMinutes(new Date(`${date}T${startTime}:00`), duration), 'HH:mm');
};

export const add30Minutes = (time: string): string => {
  return format(addMinutes(new Date(`2021-01-01T${time}:00`), 30), 'HH:mm');
};

export const substract30Minutes = (time: string): string => {
  return format(addMinutes(new Date(`2021-01-01T${time}:00`), -30), 'HH:mm');
};

export const allTimes = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

export const isInThePast = (timeSlot: string, date: Date) => {
  const [hour, minutes] = timeSlot.split(':').map(Number);

  const slotDateTime = new Date(date);
  slotDateTime.setHours(hour, minutes, 0, 0);
  const slotChisinauDate = getChisinauDate(slotDateTime);

  const now = new Date();
  const nowChisinauDate = getChisinauDate(now);

  return isBefore(slotChisinauDate, nowChisinauDate);
};
